import React from 'react'
import Layout from '../../components/Layout'

class OurWork extends React.Component {
  render() {
    return (
      <Layout>
        <h1>Hi Our Work page</h1>
      </Layout>
    )
  }
}

export default OurWork
